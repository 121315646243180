.home-header {
  background-color: $bg-main-color;
  padding-bottom: 90px;

  &-left {
    margin-top: 200px;
    max-width: 549px;

    @include screen-small-laptop {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-right {
    position: relative;
    margin-top: 130px;
    max-width: 525px;
    margin-left: auto;

    @include screen-small-laptop {
      margin-right: auto;
    }
  }

  & .search-box {
    margin-top: 36px;
    width: 100%;
  }

  & .home-top-rated {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: translate(-70%, 20%);

    @include screen-small-laptop {
      display: none;
    }
  }
}

.home-company-slider {
  & .swiper-slide {
    &:not(.swiper-slide-active) {
      filter: blur(2px);
    }
  }
}
.home-company-card {
  width: 384px !important;
  padding: 40px;
  background-color: $white-color;
  box-shadow: 0px 0px 34px rgba(45, 53, 64, 0.08);
  border-radius: 14px;

  & .logo {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: contain;
  }
}

.company-search-list-item {
  width: 100%;
  gap: 12px;

  & .logo {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: contain;
  }
}

.home-reviews {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 50px;

  &-group {
    position: relative;
    margin-top: 60px;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      top: 0px;
      width: 190px;
      height: 100%;
      z-index: 20;

      @include screen-tablet {
        width: 40px;
      }
    }

    &::before {
      left: 0px;
      background: linear-gradient(270deg, rgba(250, 250, 250, 0) 0%, #fafafa 41.15%);
    }
    &::after {
      right: 0px;
      background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, #fafafa 41.15%);
    }
  }
}

.review-card {
  width: 282px;
  background: $white-color;
  box-shadow: 0px 0px 34px rgba(45, 53, 64, 0.08);
  border-radius: 14px;
  padding: 26px;
}

.home-works {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  background: $bg-dark-color;

  & .works-desc {
    max-width: 570px;
    text-align: center;
    margin: 54px auto 70px;
  }

  & .works-card {
    position: relative;
    width: 280px;
    text-align: center;
    margin: 0px 12px;

    & .step-mark {
      width: 80px;
      height: 80px;
      margin: 0px auto;
      border-radius: 50%;
      line-height: 80px;
      text-align: center;
      background-color: $primary-color;
      color: $secondary-color;
      font-size: $heading-2;
      font-weight: 500;
    }

    & .next-arrow {
      position: absolute;
      top: 20px;
      left: -50px;
    }

    &:nth-child(3) {
      & .next-arrow {
        transform: rotateX(180deg);
        top: 40px;
      }
    }
    &:first-child {
      & .next-arrow {
        display: none;
      }
    }
  }

  @include screen-mobile {
    .works-card {
      margin-bottom: 20px;

      &:nth-child(even) {
        margin-left: auto;
      }

      &:nth-child(3) {
        & .next-arrow {
          left: unset;
          right: -50px;
          transform: rotateZ(180deg);
        }
      }
    }
  }
}

.home-featured {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 60px;
  background-color: $bg-second-color;

  &-group {
    margin-top: 80px;

    & .swiper {
      padding-top: 20px;
    }
  }
}

.home-adv {
  position: relative;
  max-width: 330px;
  z-index: 0;

  & .home-adv-image {
    border-radius: 14px;
  }

  &::after {
    content: '';
    position: absolute;
    left: -20px;
    top: 20px;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    border-radius: 14px;
    background-color: $secondary-color;
    z-index: -1;
  }

  &-review-left {
    position: absolute;
    left: -20px;
    top: 210px;
    transform: translate(-50%, 0);
  }

  &-review-right {
    position: absolute;
    right: 0px;
    top: 210px;
    width: 346px;
    background: $white-color;
    box-shadow: 0px 0px 34px rgba(45, 53, 64, 0.08);
    border-radius: 14px;
    transform: translate(50%, 0);

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: -34px;
      width: 50px;
      height: 50px;
      transform: translate(-50%, 0);
      background-image: url(../images/thumb.png);
    }
  }
}

.home-user {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 120px;

  &-left {
    & .home-user-title {
      margin-bottom: 54px;
    }
    & .home-user-desc {
      margin-bottom: 40px;
      max-width: 377px;
    }
  }

  &-right {
    width: 100%;
    max-width: 480px;

    @include screen-small-laptop {
      .home-adv {
        margin-left: auto;
      }
    }

    @include screen-tablet {
      .home-adv {
        margin-left: 200px !important;

        & .home-adv-review-left {
          max-width: unset;
        }
      }
    }
  }
}

.home-prop-firms {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: $bg-main-color;

  & .home-adv {
    margin-right: 200px;
  }

  &-right {
    width: 100%;
    max-width: 480px;
    & .home-prop-firms-title {
      margin-bottom: 46px;
    }
    & .home-prop-firms-desc {
      margin-bottom: 40px;
      max-width: 311px;
    }
  }
}
