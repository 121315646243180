$default-gap: 24px;

.admin-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  & .admin-header {
    margin-bottom: 40px;
  }
}

.admin-home {
  & .admin-home-card-container {
    margin-top: calc(-1 * $default-gap);
    margin-right: calc(-0.5 * $default-gap);
    margin-left: calc(-0.5 * $default-gap);
  }

  & .admin-home-card {
    width: 270px;
    height: 270px;

    &-wrapper {
      padding-right: calc($default-gap * 0.5);
      padding-left: calc($default-gap * 0.5);
      margin-top: $default-gap;
    }
  }
}
