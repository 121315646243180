@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Inter:600');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:700');

iframe {
  display: none;
}

/*width*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/*thumb*/
::-webkit-scrollbar-thumb {
  background: rgb(177, 186, 201);
  border-style: none;
  border-radius: 10px;
}
/*thumb hover*/
::-webkit-scrollbar-thumb:hover {
  background: rgba(133, 186, 201, 1);
}
/*thumb pressed*/
::-webkit-scrollbar-thumb:active {
  background: rgba(90, 186, 201, 1);
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

button:focus-visible {
  outline: none;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
  background-color: $bg-main-color;
  color: $text-body-color;
}

.co-mainwrap {
  position: relative;
}

h1,
.h1 {
  font-size: $heading-1;
}

h2,
.h2 {
  font-size: $heading-2;
}

h3,
.h3 {
  font-size: $heading-3;
}

h4,
.h4 {
  font-size: $heading-4;
}

h5,
.h5 {
  font-size: $heading-5;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  color: $text-title-color;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 0px;
}

a {
  text-decoration: none;
  font-weight: 600;
}

.font-IBM {
  font-family: 'IBM Plex Sans' !important;
}

.font-inter {
  font-family: Inter !important;
}

.text-large {
  font-size: 18px !important;
  line-height: 130%;
}

.text-medium {
  font-size: 16px !important;
  line-height: 130%;
}

.text-small {
  font-size: 14px !important;
  line-height: 130%;
}

.text-tiny {
  font-size: 12px !important;
  line-height: 130%;
}

.text-regular {
  font-weight: 400 !important;
}

.text-regbold {
  font-weight: 500 !important;
}

.text-semibold {
  font-weight: 600 !important;
}

.text-bold {
  font-weight: bold !important;
}

.text-title {
  color: $text-title-color !important;
}

.text-bright {
  color: $text-bright-color !important;
}

.text-body {
  color: $text-body-color !important;
}

.text-success {
  color: $success-color !important;
}

.divider {
  width: 100%;
  border-top: 1px solid $border-color;
}

.v-divider {
  height: 100%;
  border-left: 1px solid $border-color;
}

.divider-left-vert {
  border-left: 1px solid $border-color;
}
.divider-right-vert {
  border-right: 1px solid $border-color;
}
.co-log-links {
  color: $primary-color;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: $text-desc-color;
  }
}

.flex-0 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.flex-1 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flex-2 {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.anchor {
  color: $primary-color;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.w-300p {
  width: 300px;
}
.w-150p {
  width: 150px;
}
.w-120p {
  width: 120px;
}
.w-50p {
  width: 50px;
}

@include screen-mobile-portrait {
  .w-xs-100 {
    width: 100% !important;
  }
}

@include screen-mobile {
  .w-sm-100 {
    width: 100% !important;
  }
}

@include screen-tablet {
  .w-md-100 {
    width: 100% !important;
  }
}

@include screen-small-laptop {
  .w-lg-100 {
    width: 100% !important;
  }
}

@include screen-laptop {
  .w-xl-100 {
    width: 100% !important;
  }
}

textarea {
  height: auto;
}

.tooltip {
  & .tooltip-arrow::before {
    border-top-color: $secondary-color;
  }

  & .tooltip-inner {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: $text-title-color;

    background-color: $secondary-color;
  }
}

.h-100-m {
  height: calc(100% - #{$spacer}) !important;
}

@include screen-desktop-only {
  .h-xl-100-m {
    height: calc(100% - #{$spacer}) !important;
  }
}

.recharts-pie .recharts-pie-sector .recharts-sector {
  stroke-width: 5px;
}

.co-linechart {
  & .recharts-line-dot {
    stroke: $white-color;
    stroke-width: 4px;
    fill: $primary-color;
  }

  &.revenue-chart {
    & .recharts-line-dot {
      fill: $warning-color;
    }
  }
}

.co-button.datepicker-range-end {
  border-left: 1px solid $border-color;
}

@include screen-mobile-portrait-above {
  .datepicker-range-start {
    border-radius: 4px 0 0 4px !important;
    margin-right: -1px;
  }
  .datepicker-range-end {
    border-radius: 0 4px 4px 0 !important;
  }
}

.trend-badge {
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 2px 4px;
  color: $white-color;
  font-size: 12px;
  font-weight: 600;

  & svg path {
    fill: white;
  }

  &.trend-up {
    background-color: $success-color;

    & svg {
      transform: rotate(180deg);
    }
  }
  &.trend-down {
    background-color: $danger-color;
  }
}

.min-h-0 {
  min-height: 0px;
}

.no-padding {
  padding: 0 !important;
}

.react-tel-input {
  & .co-field {
    border: 1px solid $border-color;
    border-radius: 4px;
    height: 50px;

    &:focus {
      color: $text-title-color;
      box-shadow: none;
    }
  }
  & .flag-dropdown {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    padding: 8px;
    background-color: transparent;
    border: 0px;
    border-radius: 5px;
  }

  & .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
  }
}

.icon-primary {
  & svg[method='fill'] > path,
  & svg[method='fill'] > circle {
    fill: $primary-color;
  }
  & svg[method='stroke'] > path,
  & svg[method='stroke'] > circle {
    stroke: $primary-color;
  }
}

.icon-desc {
  & svg[method='fill'] > path,
  & svg[method='fill'] > circle {
    fill: $text-desc-color;
  }
  & svg[method='stroke'] > path,
  & svg[method='stroke'] > circle {
    stroke: $text-desc-color;
  }
}

.icon-danger {
  & svg[method='fill'] > path,
  & svg[method='fill'] > circle {
    fill: $danger-color;
  }
  & svg[method='stroke'] > path,
  & svg[method='stroke'] > circle {
    stroke: $danger-color;
  }
}

.pointer {
  cursor: pointer;
}

.subpage-title {
  margin-top: 70px;
  margin-bottom: 20px;
}

.subpage-divider {
  margin-top: 50px;
  margin-bottom: 50px;
}

.section-divider {
  margin-top: 40px;
  margin-bottom: 40px;
}

.co-bg-grad {
  background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 85.13%);
}

.w-button {
  width: 180px;

  @include screen-tablet {
    width: 100%;
    max-width: 180px;
  }
}

.action-edit {
  padding: 8px;
  cursor: pointer;
  &:hover {
    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $primary-color;
    }
    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $primary-color;
    }
  }
}
.action-trash {
  padding: 8px;
  cursor: pointer;
  &:hover {
    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $danger-color;
    }
    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $danger-color;
    }
  }
}

.rnc__notification-content {
  padding: 20px 12px;
}

.rnc__notification-close-mark {
  background-color: transparent !important;
  &::after {
    font-size: 24px;
  }
}
.rnc__notification-item {
  &--success {
    border-left-width: 0px;
    background-color: $success-color;
  }
  &--warning {
    border-left-width: 0px;
    background-color: $warning-color;
  }
  &--danger {
    border-left-width: 0px;
    background-color: $danger-color;
  }
  &--info {
    border-left-width: 0px;
    background-color: $info-color;
  }
}

.square-image {
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio */

  & img {
    position: absolute;
    inset: 0;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.company-logo {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: $bg-second-color;
  object-fit: contain;
}

.review-media-container img {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  object-fit: contain;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
