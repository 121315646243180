.prop-firms-wrapper {
  margin-bottom: 90px;
}

.prop-firms-filter {
  width: 277px;
  min-width: 277px;
  flex-shrink: 0;

  &.mobile {
    width: 100%;
  }

  &-panel {
    background: $white-color;
    border: 1px solid $border-color;
    border-radius: 8px;

    & .header {
      height: 38px;
      line-height: 18px;
      padding: 10px 20px;
      background: $bg-second-color;
      border-radius: 8px 8px 0px 0px;
    }
    & .content {
      padding: 5px 20px;

      & .co-checkbox {
        margin: 12px 0px;
      }

      & .co-radiobox {
        margin: 12px 0px;
      }
    }
  }
}

.company-status {
  height: 22px;
}

.prop-firms-list {
  width: 100%;

  &-header {
    width: 100%;
    height: 44px;
    margin-bottom: 14px;
  }

  &-item {
    margin-bottom: 20px;
    border-radius: 12px;
    border: 1px solid $border-color;

    & .header {
      padding: 20px 30px;
      border-radius: 12px 12px 0px 0px;
      background-color: $bg-second-color;
      border-bottom: 1px solid $border-color;

      & .company-logo {
        width: 64px;
        height: 64px;
        border-radius: 5px;
        border: 1px solid $border-color;
        margin-right: 20px;
        background-color: $bg-second-color;
        object-fit: contain;
      }

      & .v-divider {
        height: 36px;
      }

      @include screen-mobile {
        padding: 20px;
      }
    }

    & .content {
      background-color: $white-color;
      padding: 30px;
      border-radius: 0px 0px 12px 12px;

      @include screen-mobile {
        padding: 20px;
      }
    }

    & .review-info-wrapper {
      @include screen-mobile {
        margin-top: 8px;
        margin-left: 84px;
      }
    }
  }

  &-content {
    & .pagination {
      margin-top: 30px;
    }
  }
}

.prop-firm-add-panel {
  background: $white-color;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 20px 24px;
  margin-top: 60px;

  & .co-bg-icon {
    width: 50px;
    height: 50px;
  }
}

.prop-firms-details {
  position: absolute;
  left: 50%;
  top: 300px;
  transform: translate(-50%, -50%);

  &-wrapper {
    position: relative;
    width: 100%;
    height: 480px;

    & .prop-firms-profile-banner {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }

  @include screen-tablet {
    position: static;
    transform: none;
    margin-top: -260px;

    &-wrapper {
      height: auto;
    }
  }

  &-panel {
    background: $bg-main-color;
    border: 1px solid $border-color;
    border-radius: 8px;

    &.right {
      padding: 30px 38px;

      @include screen-small-laptop-above {
        max-width: 280px;
      }
    }

    & .v-divider {
      margin: 0px 16px;
    }
    @include screen-small-laptop {
      & .v-divider {
        width: 100%;
        border-top: 1px solid $border-color;
        border-left-width: 0px;
        margin: 16px 0px;
      }
    }
  }

  &-brand {
    padding: 24px;
    border-right: 1px solid $border-color;

    & .company-logo {
      width: 200px;
      height: 100px;
      object-fit: contain;
      // border: 1px solid $border-color;
      border-radius: 8px;
    }

    @include screen-mobile-portrait {
      border-right-width: 0px;
      border-bottom: 1px solid $border-color;
    }
  }
}

.prop-firms-info {
  width: 100%;
  background-color: $white-color;
  padding: 40px 20px;

  &-wrapper {
    width: 100%;
    max-width: 792px;
    margin: 0px auto;
  }

  &-tabs {
    margin-bottom: 40px;
  }
}

.review-statistic-panel {
  background: $white-color;
  border: 1px solid $border-color;
  border-radius: 8px;
  & .header {
    background: $bg-second-color;
    border-bottom: 1px solid $border-color;
    border-radius: 8px 8px 0px 0px;
    padding: 20px 25px;
  }
  & .content {
    border-radius: 0px 0px 8px 8px;
    padding: 24px;

    & .progress-items + .progress-items {
      margin-top: $spacer;
    }
  }
}

.reviews-lists {
  margin-top: 40px;
}

.reviews-list-item,
.comments-list-item {
  background: $white-color;
  border: 1px solid $border-color;
  border-radius: 8px;
  & .header {
    background: $bg-second-color;
    border-bottom: 1px solid $border-color;
    border-radius: 8px 8px 0px 0px;
    padding: 20px 25px;
  }
  & .content {
    border-radius: 0px 0px 8px 8px;
    padding: 24px;
  }

  & + .reviews-list-item {
    margin-top: $spacer;
  }
  & + .comments-list-item {
    margin-top: $spacer;
  }

  &-report {
    position: relative;
    background: $bg-main-color;
    border-radius: 8px;
    padding: 16px 25px 16px 50px;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 20px;
      left: 27px;
      background: url(../images/icon-reply-outline.svg);
    }
  }
}

.prop-firms-faq {
  padding: 16px 32px;
  background: $white-color;
  border-bottom: 1px solid $border-color;
  border-radius: 8px;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  &.opened {
    background-color: $bg-main-color;
  }

  & + .prop-firms-faq {
    margin-top: 16px;
  }
}

.prop-firms-package {
  &-wrapper {
    width: 100%;
    max-width: 996px;
    margin: 0px auto;
  }

  &-types {
    margin: 0px auto;
  }

  &-types-btn {
    min-width: 180px;
  }

  &-table {
    background-color: $white-color;

    & .column {
      &:first-child .header {
        border-top-left-radius: 8px;
      }
      &:last-child .header {
        border-top-right-radius: 8px;
      }
      &:nth-child(even) {
        background-color: $bg-second-color;
      }

      & .header {
        background-color: $white-color;
      }

      width: 100%;
      min-width: 100px;
      & > div {
        text-align: center;
        height: 84px;
        line-height: 84px;

        &:not(:last-child) {
          border-bottom: 1px solid #e9e9e9;
        }
      }
    }

    & .header-column {
      flex: 0 0 230px;

      @include screen-mobile {
        flex: 0 0 180px;
      }
    }
  }
}

.prop-firms-panel {
  background: $white-color;
  padding: 24px;
  box-shadow: 0px 0px 34px rgba(45, 53, 64, 0.08);
  border-radius: 8px;
}

.prop-firms-more-info {
  & .media-item {
    & .btn-media-item-report {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 2;
    }
  }

  & .video-item {
    position: relative;
    padding-top: 50%; /* 2:1 Aspect Ratio */

    & img {
      position: absolute;
      inset: 0;
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }

    & .btn-video-item-report {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 2;
    }
  }

  & .co-slider {
    &-nav-v-center {
      padding: 0px;
      width: calc(100% + 52px);
      left: -26px;
    }
  }
}
