.compare-wrapper {
  margin-bottom: 90px;
}

.co-select-firm {
  height: $input-height-lg;
  background-color: $bg-second-color;
  border-radius: 8px;
  cursor: pointer;

  & .co-dropdown-list {
    padding: 16px;
    overflow: hidden;
    min-width: 346px;

    & .company-listitem img {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      object-fit: contain;
    }
  }

  & .candidates-wrapper {
    width: 100%;
    height: calc($dropdown-height - 96px);
    margin: 8px 0px;

    overflow-y: auto;
  }
}

.selected-prop-firm {
  position: relative;
  background: $bg-second-color;
  border: 1px solid $border-color;
  border-radius: 100px;
  padding: 16px 24px;
  margin-right: 16px;
  margin-bottom: 16px;

  & .logo {
    width: 30px;
    height: 30px;
    border: 1px solid $border-color;
    border-radius: 4px;
    margin-right: 10px;
    object-fit: contain;
  }

  & .btn-circle-close {
    position: absolute;
    top: -7px;
    right: -4px;
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 50%;
    background-color: $white-color;
    cursor: pointer;
  }

  @include screen-tablet {
    padding: 8px 16px;

    & .btn-circle-close {
      right: -12px;
    }
  }
}

$table-header-width: 240px;
$table-header-mobile-width: 200px;

.compare-table {
  &-wrapper {
    position: relative;
    margin-left: $table-header-width;
    width: calc(100% - $table-header-width);

    @include screen-tablet {
      margin-left: $table-header-mobile-width;
      width: calc(100% - $table-header-mobile-width);
    }
  }
  &-header {
    position: absolute;
    left: calc(1px - 1 * $table-header-width);
    top: -1px;
    width: $table-header-width;
    z-index: 1;

    @include screen-tablet {
      left: calc(1px - 1 * $table-header-mobile-width);
      width: $table-header-mobile-width;
    }

    & .compare-table-cell {
      border-left: 1px solid $border-color;
      &:nth-child(2) {
        border-top: 1px solid $border-color;
        border-radius: 8px 0 0 0;
      }
      &:first-child {
        visibility: hidden;
      }
      &:last-child {
        border-radius: 0 0 0 8px;
      }
    }
  }

  & .co-slider {
    border: 1px solid $border-color;
    border-radius: 8px;
  }

  &-header {
    position: absolute;
  }
  &-cell {
    border: 1px solid $border-color;
    border-top: 0px;
    border-left: 0px;
    padding: 10px 15px;
    background-color: $bg-second-color;

    &:nth-child(odd):not(:first-child) {
      background-color: $white-color;
    }
  }

  &-logo {
    padding: 27px 15px;
    text-align: center;

    & img {
      width: 50px;
      height: 50px;
      border: 1px solid $border-color;
      border-radius: 5px;
      margin-bottom: 12px;
      object-fit: contain;
    }
  }

  &-company {
    .compare-table-logo {
      border-top: 1px solid $border-color;
    }
    &.first .compare-table-cell {
      border-left: 1px solid $border-color;
    }
    &.first .compare-table-cell:first-child {
      border-radius: 8px 0px 0px 0px;
    }
    &.first .compare-table-cell:last-child {
      border-radius: 0px 0px 0px 8px;
    }
    &.last .compare-table-cell:first-child {
      border-radius: 0px 8px 0px 0px;
    }
    &.last .compare-table-cell:last-child {
      border-radius: 0px 0px 8px 0px;
    }
  }
}

.swiper-scrollbar-horizontal {
  height: 8px !important;
  background: $border-color;
}

.swiper-scrollbar-drag {
  background-color: $primary-color;
}
