.co-login-page {
  background-color: $bg-main-color;
  padding: 100px 0px;

  & .login-left-panel {
    width: 100%;
    max-width: 390px;

    @include screen-tablet {
      margin-left: auto;
      margin-right: auto;
    }
  }

  & .logo-login {
    margin-left: 40px;
    max-width: 550px;

    & img {
      border-radius: 14px;
    }
  }

  & .login-btns {
    width: 40px;
    height: 40px;
    background: $white-color;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
