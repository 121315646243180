/* Colors */
$primary-color: #1364dc;
$secondary-color: #50d7c6;
$outline-color: #cbdbea;
$green-color: #06c270;
$yellow-color: #f5d215;
$red-color: #d93232;
$orange-color: #e8a626;
$white-color: #ffffff;
$disabled-color: #EEEEF0;

$success-color: $green-color;
$warning-color: $orange-color;
$danger-color: $red-color;
$info-color: $secondary-color;

$primary: $primary-color;
$secondary: $secondary-color;
$success: $success-color;
$warning: $warning-color;
$danger: $danger-color;

$bg-main-color: #f4f8f9;
$bg-second-color: #f9f9f9;
$bg-dark-color: #072045;

$border-color: $outline-color;

$gray-color: #8e98a1;
$gray-900: #160042;

$text-title-color: #072045;
$text-body-color: #535b65;
$text-bright-color: #8898ac;
$text-desc-color: $gray-color;

$primary-hover-color: rgba($primary-color, 0.8);
$secondary-hover-color: rgba($secondary-color, 0.8);
$danger-hover-color: rgba($danger-color, 0.8);
$success-hover-color: rgba($success-color, 0.8);

$progress-color: #39b3da;
$toggle-bg-color: #e6e8ea;

$heading-1: 50px;
$heading-2: 38px;
$heading-3: 30px;
$heading-4: 24px;
$heading-5: 20px;

$input-height-xl: 60px;
$input-height-lg: 53px;
$input-height-md: 40px;
$input-height-sm: 30px;

$header-height: 80px;

$sidenav-width: 164px;
$sidenav-mobile-width: 42px;

$admin-sidenav-width: 230px;
$admin-sidenav-mobile-width: 61px;

$dropdown-height: 360px;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$grid-gutter-width: $spacer;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1440px;

$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px,
);
