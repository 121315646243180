.dashboard-header {
  margin-bottom: 40px;
}
.dashboard-home {
  & h4 {
    margin-bottom: 30px;
  }

  &-panel {
    background: $white-color;
    box-shadow: 0px 0px 34px rgba(45, 53, 64, 0.08);
    border-radius: 8px;
  }

  & .invite-panel {
    background: $bg-main-color;
    border-radius: 12px;
    padding: 32px 52px;
  }

  & .date-range-content {
    background: $white-color;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 8px 14px;
    max-width: 300px;

    & .co-datepicker {
      width: 100%;
    }

    & .date-range-item {
      padding: 5px 0px;
    }

    & .v-divider {
      height: 24px;
      margin: 0px 12px;
    }
  }
}

.dashboard-profile {
  & .co-card {
    padding: 40px 30px;
  }

  & .company-logo {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background-color: $bg-second-color;
    object-fit: contain;
  }

  & .company-banner {
    width: 100%;
    height: 192px;
    border-radius: 8px;
    background-color: $bg-second-color;
    object-fit: cover;
  }

  & .divider {
    margin: 40px 0px;
  }

  & .btnSave {
    width: 220px;
    // margin: 0px auto;
  }

  & .profile-preview-container {
    position: absolute;
    inset: 0;
    top: $header-height;
  }

  & .profile-preview-back {
    position: absolute;
    top: 320px;
    right: 20px;
  }
}

.dashboard-faqs {
  & .dashboard-faq {
    padding: 0px;

    & .header {
      padding: 6px 24px;
      border-bottom: 1px solid $border-color;
    }
  }
}

.dashboard-payment {
  & .btn-add-card {
    color: $primary-color;
    cursor: pointer;
  }

  & .invoice-history {
    margin-top: 40px;
  }
}

.select-month {
  width: 100px;
  & .co-dropdown-list {
    width: 100px;
    max-height: 180px;
  }
}

.select-year {
  width: 90px;

  & .co-dropdown-list {
    width: 90px;
    max-height: 180px;
  }
}

.invoice-status {
  font-family: 'Noto Sans';
  font-size: 12px;
  color: #3b3e45;
  padding: 4px 8px;
  border-radius: 4px;

  &.Paid {
    background-color: rgba(18, 189, 178, 0.11);
  }

  &.Due {
    background-color: rgba(189, 18, 18, 0.11);
  }
}

.dashboard-reviews {
  & .reviews-section {
    margin-top: 30px;
  }
}

.dashboard-reports {
  & .report-image {
    & img {
      width: 84px;
      height: 84px;
      border-radius: 8px;
    }
  }
  & .report-action {
    background: $bg-second-color;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 0px 12px;
  }
}

.dashboard-widgets {
  & .widgets-block {
    background-color: $bg-main-color;
    border-radius: 8px;
  }
}

.prop-firm-widget-card {
  cursor: pointer;

  & .content {
    position: relative;
    padding: 14px;
    height: 170px;

    & .badge-popular {
      position: absolute;
      left: 14px;
      bottom: 14px;
      font-weight: 500;
      color: $white-color;
      background-color: $secondary-color;
      border-radius: 100px;
      padding: 4px 8px;
    }
  }

  & .footer {
    padding: 14px;
    height: 50px;
  }
}

.notification-list-item {
  &-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $bg-second-color;
    // border: 2px solid $text-title-color;
  }

  &-time {
    font-family: 'Outfit';
    font-size: 12px;
    font-weight: 500;
    color: #6c6c6c;
  }
}
