.profile-header {
  background-color: $bg-main-color;
}

.action-panel {
  padding: 24px;
  background: $primary-color;
  border-radius: 8px;

  @include screen-small-laptop-above {
    padding: 46px 62px;
  }
}

.profile-details-container {
  margin-top: 70px;
  margin-bottom: 50px;
}

.profile-details-wrapper {
  border: 1px solid $border-color;
  border-radius: 8px;

  & .profile-details {
    padding: 24px 24px;

    & .detail-item {
      width: 106px;
      text-align: center;
    }
    & .detail-icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $primary-color;
      margin: 0px auto 10px;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $white-color;
      }
      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $white-color;
      }
    }

    & .divider {
      margin: 24px 0px;
    }

    @include screen-mobile-above {
      padding: 36px 0px;
      & .divider {
        width: 0px;
        border-top: 0px;
        height: 100%;
        border-left: 1px solid $border-color;
        margin: 0px;
      }
    }
  }
}

.profile {
  &-wrapper {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  &-view-wrapper {
    margin-top: 40px;
  }

  &-panel {
    padding: 30px;
    background: $white-color;
    border: 1px solid $border-color;
    border-radius: 12px;
  }

  &-reviews-info {
    background: $white-color;
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 24px;
  }
}

.profile-review-item {
  & .action {
    background: $bg-second-color;
    border: 1px solid $border-color;
    border-radius: 8px;
  }
}
