.co-label {
  color: $text-title-color;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 8px;
  font-weight: 500;
}

.co-label-large {
  color: $text-title-color;
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 8px;
}

.co-field-wrapper {
  position: relative;
}

.co-field {
  width: 100%;
  margin-bottom: 0px;
  border-radius: 8px;
  border: 1px solid $border-color;
  color: $text-title-color;
  font-size: 16px;
  padding: 16px;
  vertical-align: middle;

  &.password {
    padding-right: 50px;
  }

  &.no-border {
    background-color: $bg-second-color;
    border: 0px;
  }

  &.phone {
    padding-left: 56px;
  }

  &.icon-left {
    padding-left: 46px;
  }

  &.icon-right {
    padding-right: 46px;
  }

  &.prepend {
    padding-left: 36px;
  }

  &.append {
    padding-right: 36px;
  }

  &:focus {
    border-color: $primary-color;
    outline: 0;
  }

  &-icon-left,
  &-icon-right {
    position: absolute;
    top: 50%;
    bottom: auto;
    padding: 9px 15px;
    cursor: pointer;
    transform: translate(0, -50%);
  }

  &-prepend,
  &-append {
    position: absolute;
    top: 50%;
    bottom: auto;
    padding: 9px 8px 9px 15px;
    font-weight: 600;
    transform: translate(0, -50%);
  }

  &-icon-left,
  &-prepend {
    left: 5px;
    right: auto;
  }

  &-icon-right,
  &-append {
    right: 5px;
    left: auto;
  }

  &-xl {
    height: $input-height-xl;
  }

  &-lg {
    height: $input-height-lg;
  }

  &-md {
    height: $input-height-md;
  }

  &-sm {
    height: $input-height-sm;
  }

  ::placeholder {
    color: $text-bright-color;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $text-bright-color;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $text-bright-color;
  }
}

.co-field-textarea {
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  border: 1px solid $border-color;
  border-radius: 4px;
  color: $text-title-color;
  font-size: 16px;
  padding: 16px;

  &.no-border {
    background-color: $bg-second-color;
    border: 0px;
  }

  &:focus {
    border-color: $primary-color;
    outline: 0;
  }

  &:focus-visible {
    outline: 0;
  }
}

.co-field-error {
  margin-top: 8px;
  color: $danger-color;
  font-size: 14px;
}

.co-button {
  border-radius: 8px;
  border: 0;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &.square {
    flex: 0 0;
  }

  &.btn-size-auto {
    padding: 0px;
  }

  &.btn-size-xl {
    height: 60px;
    padding: 10px 40px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 700;
    border-radius: 10px;

    &.square {
      padding: 12px;
      width: 60px;
      min-width: 60px;
    }
  }

  &.btn-size-lg {
    height: 52px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 700;

    &.square {
      padding: 12px;
      width: 52px;
      min-width: 52px;
    }
  }

  &.btn-size-md {
    height: 45px;
    padding: 12px 26px;
    font-size: 16px;
    line-height: 1.3;

    &.square {
      padding: 12px;
      width: 45px;
      min-width: 45px;
    }
  }

  &.btn-size-sm {
    height: 40px;
    padding: 12px 26px;
    font-size: 14px;
    line-height: 1.3;

    &.square {
      padding: 12px;
      width: 40px;
      min-width: 40px;
    }
  }

  &.btn-size-xs {
    height: 30px;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 1.4;

    &.square {
      padding: 8px;
      width: 30px;
      min-width: 30px;
    }
  }

  &.circle {
    border-radius: 50%;
  }

  &.primary {
    color: $white-color;
    background-color: $primary-color;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $white-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $white-color;
    }

    &:hover {
      background-color: $primary-hover-color;
    }
  }

  &.outline-primary {
    color: $primary-color;
    background-color: $white-color;
    border: 1px solid $primary-color;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $primary-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $primary-color;
    }

    &:hover {
      background-color: $primary-color;
      color: $white;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $white-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $white-color;
      }
    }
  }

  &.hover-primary {
    color: $gray-900;
    background-color: transparent;

    &:hover {
      color: $white-color;
      background-color: $primary-color;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $white-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $white-color;
      }
    }
  }

  &.hover-outline-primary {
    color: $text-title-color;
    background-color: $white-color;
    border: 1px solid $border-color;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $text-title-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $text-title-color;
    }

    &:hover {
      border: 1px solid $primary-color;
      color: $primary;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $primary-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $primary-color;
      }
    }
  }

  &.secondary {
    color: $white-color;
    background-color: $secondary-color;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $white-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $white-color;
    }

    &:hover {
      background-color: $secondary-hover-color;
    }
  }

  &.hover-secondary {
    background-color: transparent;

    &:hover {
      background-color: $secondary-color;
    }
  }

  &.hover-secondary-text {
    background-color: transparent;
    font-weight: 400;

    &:hover {
      background-color: $secondary-color;
      color: $primary-color;
      font-weight: 600;
    }
  }

  &.hover-secondary-text-checked {
    background-color: $secondary-color;
    color: $primary-color;
    font-weight: 600;
  }

  &.no-padding {
    padding: 0;
  }

  &.normal {
    color: $primary-color;
    background-color: $white-color;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $primary-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $primary-color;
    }

    &:hover {
      background-color: $primary-color;
      color: $white-color;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $white-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $white-color;
      }
    }
  }

  &.gray {
    color: $text-desc-color;
    background-color: $bg-main-color;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $text-desc-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $text-desc-color;
    }

    &:hover {
      background-color: $secondary-color;
    }
  }

  &.danger {
    color: $white-color;
    background-color: $danger-color;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $white-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $white-color;
    }

    &:hover {
      background-color: $danger-hover-color;
    }
  }

  &.calendar {
    color: $text-title-color;
    background-color: $white-color;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $gray-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $gray-color;
    }

    &:hover {
      color: $primary-color;
    }
  }

  &.transparent {
    color: $text-title-color;
    background-color: transparent;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $text-title-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $text-title-color;
    }
  }

  &.link {
    color: $primary-color;
    background-color: transparent;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $primary-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $primary-color;
    }

    &:hover {
      color: $primary-hover-color;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $primary-hover-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $primary-hover-color;
      }
    }
  }

  &.hover-link {
    color: $text-title-color;
    background-color: transparent;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $text-title-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $text-title-color;
    }

    &:hover {
      color: $primary-color;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $primary-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $primary-color;
      }
    }
  }

  &.link-danger {
    color: $danger-color;
    background-color: transparent;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $danger-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $danger-color;
    }

    &:hover {
      color: $danger-hover-color !important;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $danger-hover-color !important;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $danger-hover-color !important;
      }
    }
  }

  &.link-success {
    color: $success-color !important;
    background-color: transparent;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $success-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $success-color;
    }

    &:hover {
      color: $success-hover-color !important;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $success-hover-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $success-hover-color;
      }
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.4;
  }
}

.co-button-group {
  width: fit-content;
  width: -moz-fit-content;

  &.theme-default > .co-button {
    border-radius: 0px;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &.theme-normal {
    background-color: $bg-main-color;
    border-radius: 6px;
  }

  &.theme-hover-primary {
    background-color: $bg-main-color;
    border-radius: 8px;

    & .co-button {
      border-radius: 4px;
      margin: 8px;
    }
  }

  &.theme-secondary {
    background-color: $secondary-color;
    border-radius: 6px;
  }

  &.theme-transparent > .co-button {
    border: 1px solid $border-color;
    border-right: 0px;
    border-radius: 0px;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right: 1px solid $border-color;
    }

    &.primary {
      border: 1px solid $primary-color;

      &:last-child {
        border-right: 1px solid $primary-color;
      }
    }
  }
}

.fc-red {
  color: $danger-color;
}

.co-top-search-field {
  position: relative;
  width: 240px;

  & input {
    background-color: $bg-main-color;
    height: 40px;
  }
}

.co-avatar {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;

  color: $white-color;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  object-fit: cover;

  &-lg {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    min-width: 50px;
  }

  &-md {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    min-width: 40px;
  }

  &-sm {
    width: 30px;
    height: 30px;
    min-width: 30px;
  }

  &.no-avatar {
    background-color: $yellow-color;
  }

  &.green {
    background-color: $secondary-color;
  }

  &.yellow {
    background-color: $yellow-color;
  }
}

.co-dropdown {
  position: relative;

  &-toggle {
    &:not(.disabled) {
      cursor: pointer;
    }

    &.disabled {
      & .co-dropdown-toggle-icon {
        & svg[method='fill'] > path,
        & svg[method='fill'] > circle {
          fill: $disabled-color;
        }

        & svg[method='stroke'] > path,
        & svg[method='stroke'] > circle {
          stroke: $disabled-color;
        }
      }
    }
  }

  &-list {
    position: absolute;
    //visibility: hidden;
    // min-width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: $white-color;
    z-index: $zindex-popover;
    max-height: $dropdown-height;
    // overflow: auto;
    overflow-y: auto;
    box-shadow: 0px 0px 34px rgba(45, 53, 64, 0.08);
    border-radius: 10px;

    &.show-arrow {
      margin-top: 16px;

      &::after {
        content: '';
        position: absolute;
        top: -8px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $white-color;
        box-shadow: 0 6px 32px 0 rgba(142, 152, 161, 0.16);
      }

      &.bottom-left::after {
        left: 12px;
      }

      &.bottom-right::after {
        right: 12px;
      }

      &.bottom-center::after {
        left: calc(50% - 4px);
      }
    }

    &.bottom-left {
      top: 100%;
      left: 0px;
      right: unset;
    }

    &.bottom-right {
      top: 100%;
      right: 0px;
      left: unset;
    }

    &.bottom-center {
      top: 100%;
      left: 50%;
      right: unset;
      transform: translate(-50%, 0);
    }

    &.top-left {
      bottom: 100%;
      left: 0px;
    }

    &.top-right {
      bottom: 100%;
      right: 0px;
    }

    &.w--open {
      visibility: visible;
    }
  }

  &-link {
    display: flex;
    margin: -10px;
    padding: 10px;
    color: $text-title-color;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $text-title-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $text-title-color;
    }

    &:hover {
      color: $text-title-color;
      background-color: $bg-main-color;
    }
  }

  &-divider {
    width: 100%;
    height: 16px;
  }
}

.co-card {
  position: relative;
  padding: 14px;
  border-radius: 8px;
  background-color: $white-color;
  box-shadow: 0px 0px 34px rgba(45, 53, 64, 0.08);

  &:not(.fit-content) {
    min-height: 0;
  }

  &-header {
    margin-bottom: $spacer;
    font-size: 14px;
  }

  &-subtitle {
    color: $text-body-color;
  }

  &-action > a {
    font-weight: 600;
    text-decoration: none;
  }
}

.co-checkbox {
  cursor: pointer;

  &-box {
    width: 18px;
    height: 18px;
    border: 1px solid $text-bright-color;
    background-color: $white-color;
    border-radius: 4px;
  }

  &.circle {
    .co-checkbox-box {
      border-radius: 50%;
    }
  }

  &-text {
    font-size: 14px;
    color: $text-title-color;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.bordered {
    padding: 15px;
    border: 1px solid $border-color;
    border-radius: 5px;
    cursor: pointer;

    &.co-checkbox-sm {
      padding: 10px 15px;
    }
  }

  &.checked {
    &.bordered {
      border: 1px solid $primary-color;
    }

    .co-checkbox-box {
      border-width: 0px;
      background-image: url(../images/icon-check-white.svg);
      background-position: center;
      background-repeat: no-repeat;
    }

    &.primary {
      .co-checkbox-box {
        background-color: $primary-color;
      }
    }

    &.secondary {
      .co-checkbox-box {
        background-color: $secondary-color;
      }
    }
  }

  &.disabled {
    &.checked .co-checkbox-box {
      background-color: $bg-main-color;
    }
  }
}

.co-radiobox {
  cursor: pointer;

  &-box {
    width: 20px;
    height: 20px;
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 50%;
  }

  &-text {
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.bordered {
    padding: 15px;
    border: 1px solid $border-color;
    border-radius: 5px;
    cursor: pointer;

    &.co-radiobox-sm {
      padding: 10px 15px;
    }
  }

  &.checked {
    &.bordered {
      border: 1px solid $primary-color;
    }

    .co-radiobox-box {
      background-color: $primary-color;
    }
  }

  &.disabled {
    &.checked .co-radiobox-box {
      background-color: $gray-color;
    }
  }
}

.co-toggle {
  font-size: 14px;
  font-weight: 500;
  color: $text-title-color;
  cursor: pointer;

  &-wrapper {
    width: 80px;
    height: 38px;
    border-radius: 40px;
    background-color: $toggle-bg-color;
    padding: 4px;
    flex: 0 0 auto;
  }

  &-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white-color;
  }

  &-md {
    .co-toggle-wrapper {
      width: 36px;
      height: 20px;
      border-radius: 20px;
      padding: 2px;
    }

    .co-toggle-icon {
      width: 16px;
      height: 16px;
    }
  }

  &.checked {
    & .co-toggle-wrapper {
      background-color: $primary-color;
      justify-content: flex-end;
    }

    & .co-toggle-icon {
      background-color: $white-color;
    }
  }

  &.both {
    & .co-toggle-wrapper {
      background-color: $primary-color;
    }

    & .co-toggle-icon {
      background-color: $white-color;
    }
  }

  &.disabled {
    .co-toggle-icon {
      background-color: $gray-color;
    }
  }
}

.co-slider {
  position: relative;

  &.no-padding-bottom .swiper {
    padding-bottom: 0px;
  }

  & .swiper-slide {
    width: auto;
  }

  & .swiper-pagination {
    text-align: center;
  }

  & .swiper-horizontal {
    padding-bottom: 40px;
  }

  & .swiper-horizontal > .swiper-pagination-bullets {
    bottom: 0px;

    & .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background-color: #8798ac;
      margin: 0 12px;

      &.swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background-color: #1c1c1c;
      }
    }
  }

  &-nav-bottom {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 2;
  }

  &-nav-v-center {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0px 20px;
    z-index: 2;
    justify-content: space-between;
  }
}

.co-calendar-range-text {
  font-size: 12px;
}

.calendar-top-menu {
  & .calendar-view-mode {
    @include screen-mobile {
      width: 100%;
      margin-bottom: $spacer;
    }
  }

  & .calendar-view-date-range {
    @include screen-mobile-portrait {
      width: 100%;
      margin-bottom: $spacer;
    }
  }

  & .calendar-view-add-task {
    @include screen-mobile-portrait {
      width: 100%;
    }
  }
}

.co-table {
  min-height: 0;
  position: relative;

  &-row-header {
    position: sticky;
    top: 0;
    background-color: $white-color;
    min-width: fit-content;
  }

  &-header-cell {
    padding: 12px 16px;
    color: $text-body-color;
  }

  &-row-wrapper {
    min-width: fit-content;
  }

  &-row {
    -webkit-transition: box-shadow 200ms ease;
    transition: box-shadow 200ms ease;
    box-shadow: none;

    // &:hover {
    //   // color: $gray-color;
    //   box-shadow: 0 8px 36px 0 rgba(28, 28, 28, 0.09);
    // }

    &:first-child {
      & .co-table-cell {
        border-top: 1px solid $border-color;
      }
    }
    &:last-child {
      & .co-table-cell {
        border-bottom-width: 0px;
      }
    }
  }

  &-cell {
    padding: 16px;
    border-bottom: 1px solid $border-color;

    &.border-left {
      border-left: 1px solid $border-color;
    }
    &.border-right {
      border-right: 1px solid $border-color;
    }
    &.border-top {
      border-top: 1px solid $border-color;
    }

    &:first-child {
      border-left-width: 0px;
    }
  }
}

.co-modal {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  padding: $spacer;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  cursor: default;
  z-index: $zindex-modal-backdrop;
}

.co-modal-mask {
  position: absolute;
  inset: 0%;
  z-index: $zindex-modal-backdrop;
}

.co-modal-container {
  position: absolute;
  z-index: $zindex-modal;
  width: 100%;
  border-radius: 12px;
  background-color: $white-color;
  box-shadow: 0px 0px 34px rgba(45, 53, 64, 0.08);
  margin-left: auto;
  margin-right: auto;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  & .co-modal-container {
    transform: translate(-50%, -50%);
  }

  @include screen-tablet {
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0, 0);

    & .co-modal-container {
      transform: translate(0, 0);
    }
  }

  &.sm {
    max-width: 380px;
  }

  &.md {
    max-width: 480px;
  }

  &.lg {
    max-width: 620px;
  }

  &.xl {
    max-width: 900px;
  }
}

.modal-file-uploader {
  overflow: unset;
}

.co-modal-close {
  margin: 12px;
}

.co-modal-title {
  padding: 24px 24px 20px 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.co-modal-content {
  max-height: 640px;
  overflow-y: auto;

  &:not(.content-full) {
    padding: 14px 24px;
  }
}

.co-modal-footer {
  padding: 24px;
  border-top: 1px solid $border-color;
}

.co-pagination {
  &-page {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    color: $text-bright-color;
    user-select: none;

    &:hover,
    &.current {
      color: $primary-color;
      font-weight: 700;
    }
  }

  &.secondary {
    & .co-pagination-page {
      font-family: 'Open Sans';
      font-weight: 600;
      border-radius: 50%;
      color: $text-title-color;
      background-color: $bg-main-color;

      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $text-title-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $text-title-color;
      }

      + .co-pagination-page {
        margin-left: 8px;
      }

      &:hover {
        background-color: $primary-hover-color;
        color: $white-color;
        & svg[method='fill'] > path,
        & svg[method='fill'] > circle {
          fill: $white-color;
        }

        & svg[method='stroke'] > path,
        & svg[method='stroke'] > circle {
          stroke: $white-color;
        }
      }
      &.current {
        background-color: $primary-color;
        color: $white-color;
      }
    }
  }
}

.co-circular-progressbar {
  & .CircularProgressbar-trail {
    stroke: $secondary-color;
  }

  & .CircularProgressbar-path {
    stroke: $primary-color;
  }

  & .CircularProgressbar-text {
    font-size: 28px;
    font-weight: 600;
    fill: $text-title-color;
  }
}

.co-group-panel {
  background-color: $bg-main-color;
  border-radius: 5px;
  padding: 15px;
}

.co-bg-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary-color;
  border-radius: 50%;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.co-select {
  border: 1px solid $border-color;
  border-radius: 8px;
  height: $input-height-md;
  font-size: 14px;
  font-weight: 500;

  &.no-border {
    background: $bg-second-color;
    border: 0px;
  }

  &.disabled {
    background-color: $bg-main-color;
  }

  &.co-select-xl {
    height: $input-height-xl;
  }

  &.co-select-lg {
    height: $input-height-lg;
  }

  &.co-select-sm {
    height: $input-height-sm;
  }

  .co-select-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .co-select-item {
    padding: 12px $spacer;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: $bg-main-color;
    }
  }

  &.open {
    
    & .co-dropdown-toggle-icon {
      transform: rotate(180deg);
      
      & svg[method='fill'] > path,
      & svg[method='fill'] > circle {
        fill: $primary-color;
      }

      & svg[method='stroke'] > path,
      & svg[method='stroke'] > circle {
        stroke: $primary-color;
      }
    }
  }

  &-placeholder {
    color: $text-desc-color;
  }
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: none;
}

.react-calendar__navigation > button:enabled:hover,
.react-calendar__navigation > button:enabled:focus {
  background-color: unset;
}

.react-calendar__navigation > button:enabled:hover,
.react-calendar__navigation > button:enabled:focus {
  background-color: unset;
}

.react-calendar__navigation button {
  min-width: unset;
}

.react-calendar__navigation button[disabled] {
  background-color: unset;
}

.react-calendar__navigation__arrow {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(4, 4, 21, 0.1) !important;

  & svg[method='fill'] > path,
  & svg[method='fill'] > circle {
    fill: $text-title-color;
  }

  & svg[method='stroke'] > path,
  & svg[method='stroke'] > circle {
    stroke: $text-title-color;
  }

  &[disabled] {
    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $gray-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $gray-color;
    }
  }
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgba(45, 158, 100, 0.2);
  border-radius: 16px;
}

.react-calendar__tile--hasActive,
.react-calendar__tile--active,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $primary-color;
  border-radius: 16px;
  color: $white-color;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em;
}

.react-calendar {
  font-family: 'IBM Plex Sans', sans-serif;
  border: 0px;

  & .react-calendar__navigation {
    font-size: 16px;
    font-weight: bold;
  }

  & .react-calendar__month-view__weekdays {
    font-size: 14px;
    font-weight: 500;
  }

  & .react-calendar__tile:disabled {
    background: unset;
    color: $text-desc-color;
  }
}

.co-datepicker {
  width: fit-content;

  @include screen-mobile-portrait {
    .co-dropdown-list {
      max-width: 320px;
    }
  }
}

.co-group-list,
.co-scroll-wrapper {
  margin: calc(-1 * $spacer);
  padding: $spacer;
  overflow: auto;
  height: 100%;
  width: calc(100% + 2rem);
}

.co-group-list-x,
.co-scroll-wrapper-x {
  margin: calc(-1 * $spacer) 0;
  padding: $spacer 0;
  overflow: auto;
  width: 100%;
}

.co-colorpicker {
  text-align: center;

  &-color {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  &-lg {
    height: $input-height-lg;
  }

  &-md {
    height: $input-height-md;
  }

  &-sm {
    height: $input-height-sm;
  }
}

.co-numberpicker {
  text-align: center;

  &-lg {
    height: $input-height-lg;
  }

  &-md {
    height: $input-height-md;
  }

  &-sm {
    height: $input-height-sm;
  }
}

.co-rating {
  .co-rating-item {
    position: relative;

    &.primary {
      color: rgba($primary-color, .3);
    }

    &.yellow {
      color: rgba($yellow-color, .3);
    }

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: currentColor;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: currentColor;
    }

    &.primary .mask {
      color: $primary-color;
    }
    &.yellow .mask {
      color: $yellow-color;
    }

    & .mask {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      overflow: hidden;

      & svg path {
        fill: currentColor;
      }
    }

    & + .co-rating-item {
      margin-left: calc($spacer / 2);
    }
  }
}

.co-bookmark {
  cursor: pointer;
  &-number {
    color: $primary-color;
    margin-right: 12px;
  }

  &.disabled {
    cursor: default;
    & .co-bookmark-number {
      color: $text-title-color;
    }

    & .co-bookmark-icon {

      & path {
        fill: none;
        stroke: $text-title-color;
      }
    }
  }
}

.co-underline {
  position: relative;
  display: inline-block;

  &::before {
    content: ' ';
    position: absolute;
    bottom: -8px;
    left: 0px;
    width: 100%;
    height: 4px;
    background-color: $secondary-color;
  }
}

.co-breadcrumbs {
  &-link {
    color: $text-body-color;

    &:hover {
      color: $text-title-color !important;
    }
  }
}

.co-progress {
  position: relative;
  width: 100%;
  background: $bg-main-color;
  border-radius: 89px;

  &-lg {
    height: 24px;
  }

  &-md {
    height: 20px;
  }

  &-sm {
    height: 16px;
  }

  &-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0%;
    height: 100%;
    border-radius: 89px;
    -webkit-transition: width 200ms ease;
    transition: width 200ms ease;

    &.primary {
      background-color: $primary-color;
    }

    &.secondary {
      background-color: $secondary-color;
    }
  }
}

.co-upload-image {
  & img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }

  &-remove {
    display: none;
    cursor: pointer;
  }

  &:hover {
    & .co-upload-image-remove {
      display: block;
    }
  }
}

.co-country-select {
  background-color: $bg-second-color;
}

.co-country-select,
.co-phone-input {
  border-radius: 8px;
  padding: 0px 16px;

  &.bordered {
    border: 1px solid $border-color;
    background-color: transparent;
  }
  &-xl {
    height: $input-height-xl;
  }

  &-lg {
    height: $input-height-lg;
  }

  &-md {
    height: $input-height-md;
  }

  &-sm {
    height: $input-height-sm;
  }

  &-text {
    width: 100%;
    border: 0px;
    color: $text-title-color;
    font-size: 16px;
    width: 100%;
  }

  & .co-dropdown-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .filter-input {
    position: sticky;
    left: 0px;
    top: 0px;
    background-color: $white-color;
    z-index: 1;
  }

  & .co-select-item {
    margin: 1px 0px;
    padding: 12px $spacer;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: $bg-main-color;
    }
  }
}
