.co-main {
  position: relative;
  width: 100%;
  background-color: $white-color;
}

.co-main-admin {
  position: relative;
  width: 100%;
  background-color: #fafafa;
}

.co-content-wrapper {
  margin-top: 80px;
  @include screen-tablet {
    margin-top: 20px;
  }
}

.co-left-panel {
  position: sticky;
  top: calc($header-height + 20px);
  width: $sidenav-width;
  height: 100%;
  padding-bottom: 40px;

  @include screen-tablet {
    left: 12px;
    width: $sidenav-mobile-width;
  }
}

.co-admin-left-panel {
  position: sticky;
  top: 0px;
  height: 100%;
  min-height: 100vh;
  width: $admin-sidenav-width;
  border-right: 1px solid $border-color;
  padding: 0px 30px;

  & .co-brand {
    margin: 45px 0px;
  }

  @include screen-tablet {
    padding: 0px 10px;
    width: $admin-sidenav-mobile-width;

    & .co-brand {
      margin: 25px 0px;
    }
  }
}

.sidebar-navbar-link {
  padding: 12px 20px;

  @include screen-tablet {
    padding: 12px;
  }

  + .sidebar-navbar-link {
    margin-top: 12px;
  }

  & .link-icon {
    width: 18px;
    height: 18px;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $text-bright-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $text-bright-color;
    }
  }
  & .link-label {
    color: $text-title-color;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;

    @include screen-tablet {
      display: none;
    }
  }

  &.current {
    background-color: $primary-color;
    border-radius: 8px;

    & .link-label {
      color: $white-color;
    }

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $white-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $white-color;
    }
  }
}

.admin-sidebar-navbar-link {
  padding: 15px 10px;

  @include screen-tablet {
    padding: 10px;
  }

  + .admin-sidebar-navbar-link {
    margin-top: 10px;
  }

  & .link-icon {
    width: 18px;
    height: 18px;

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $text-bright-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $text-bright-color;
    }
  }
  & .link-label {
    color: $text-bright-color;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;

    @include screen-tablet {
      display: none;
    }
  }

  &.current {
    & .link-label {
      color: $primary-color;
      font-weight: 700;
    }

    & svg[method='fill'] > path,
    & svg[method='fill'] > circle {
      fill: $primary-color;
    }

    & svg[method='stroke'] > path,
    & svg[method='stroke'] > circle {
      stroke: $primary-color;
    }
  }
}

.co-content {
  min-height: 100vh;
}

.co-content-main {
  // min-height: 100vh;
}

.co-content-dashboard {
  min-height: 100vh;
  margin-left: 40px;
  width: calc(100% - ($sidenav-width + 40px));

  @include screen-tablet {
    margin-left: 10px;
    width: calc(100% - ($sidenav-mobile-width + 10px));
    padding: 0px 12px;
  }
}

.co-content-admin {
  min-height: 100vh;
  width: calc(100% - $admin-sidenav-width);
  padding: 30px 30px 30px;

  @include screen-tablet {
    width: calc(100% - $admin-sidenav-mobile-width);
    padding: 30px 10px;
  }
}

.co-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  height: $header-height;
  z-index: $zindex-sticky;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  background-color: $bg-main-color;

  &.white-theme {
    background-color: $white-color;
  }

  @include screen-mobile-portrait {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@include screen-tablet {
  .co-navmenu {
    border: 1px solid $border-color;
    border-radius: 0px 0px 8px 8px;
  }
}

.navbar {
  height: $header-height;
  padding: 0px;
  transition: all 0.4s;
}

.navbar-default {
  & .navbar-toggler {
    background: none;
    border: none;
    height: $header-height;
    padding: 18px;

    &:hover,
    &:focus {
      background: none;
      box-shadow: none;
    }

    & .icon-bar {
      display: block;
      width: 16px;
      height: 2px;
      border-radius: 1px;
      background: $text-title-color;

      & + .icon-bar {
        margin-top: 3px;
      }
    }
  }
}

@include screen-tablet {
  .navbar-header {
    width: 100%;
  }
  .navbar-collapse {
    background-color: $bg-main-color;
  }
}

.navbar-item {
  padding: 12px 16px;
  font-weight: 600;
  letter-spacing: 0.005em;

  @include screen-mobile-above {
    margin-left: 15px;
  }
}

.splitter {
  width: 0px;
  height: 24px;
  border: 1px solid $border-color;
  margin: 0px 24px;
}

.co-search-field input {
  background-color: transparent;
}

.co-footer {
  border-top: 1px solid $border-color;
  background-color: $white-color;

  & .footer-content {
    padding-top: 60px;
    padding-bottom: 100px;

    &-right {
      margin-top: 28px;

      @include screen-tablet-above {
        margin-left: 62px;
        width: 100%;
      }
    }

    @include screen-mobile-portrait {
      padding: 40px 30px 60px;
    }
  }

  & .footer-social-links {
    margin-top: 36px;
    margin-bottom: 48px;

    & .splitter {
      height: 16px;
      margin: 0px 16px;
    }
  }

  & .footer-bottom {
    height: 48px;
    background-color: $text-title-color;
    color: $white-color;
  }
}

.co-header-notification {
  position: relative;

  & .notification-badge {
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(50%, -50%);
    min-width: 28px;
    height: 20px;
    line-height: 16px;
    padding: 0 6px;
    background-color: $primary-color;
    border: 2px solid $white-color;
    border-radius: 50px;
    font-weight: 600;
    color: $white-color;
    text-align: center;
  }
}

.logout-wrapper {
  padding: 40px 10px;
}
